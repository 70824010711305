<template>
  <div class="admin">
    <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ error }}
    </div>
    <div v-if="isEdit">
      <b-container class="w-50">
        <b-card>
          <b-row class="mt-3">
            <b-col>
              <label class="form-label">Store Type</label>
              <b-form-select v-model="store.sSource" class="mb-3" @change="changed_store_type">
                <b-form-select-option value="panda">Panda Direct Print</b-form-select-option>
                <b-form-select-option value="pandaapi">Panda Api</b-form-select-option>
                <b-form-select-option value="ubereat">Uber Eat</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <label class="form-label">Store Name</label>
              <input type="text" class="form-control" v-model="store.sourceName">
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <label class="form-label">Store ID</label>
              <input type="text" class="form-control" v-model="store.sourceStoreId" @change="changed_store_id">
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="store&&store.sSource=='pandaapi'">
            <b-col>
              <label class="form-label">Store Access Key</label>
              <input type="text" class="form-control" v-model="store.sourceKey">
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="store&&store.sSource=='pandaapi'">
            <b-col>
              <label class="form-label">Store Security Key</label>
              <input type="text" class="form-control" v-model="store.sourceKey2">
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <label class="form-label">PKEnterprise Full Url (Example: https://happywood.posking.ca)</label>
              <input type="text" class="form-control" v-model="store.enterpriseUrl">
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <label class="form-label">PKEnterprise Store ID (Example: HPPY)</label>
              <input type="text" class="form-control" v-model="store.storeId">
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <label class="form-label">apiKey (For PKEnterprise, must be unique, better include Enterprise subname)</label>
              <input type="text" class="form-control" v-model="store.apiKey">
            </b-col>
          </b-row>
          <b-row class="text-center mt-5">
            <b-col cols="6">
              <button class="btn btn-secondary" @click="cancel()">Cancel</button>
            </b-col>
            <b-col cols="6">
              <button class="btn btn-success" @click="save()">Submit</button>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="storetest" class="text-center">
          <div v-if="test_message" class="text-left">
            <div v-html="test_message" />
          </div>
          <b-row class="mt-3" v-if="store.sSource=='ubereat'">
            <b-col>
              <b-button variant="primary" @click="test_uber_store">Test Uber Eat Store Access</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="upload_store_menu">Upload Store Menu</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_uber_info">Get Uber Eat Info</b-button>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="store.sSource=='pandaapi'">
            <b-col>
              <b-button variant="primary" @click="get_panda_info('getCategory')">Get Category</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_panda_info('uploadCategory')">Update Category</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_input_id('deleteCategory')">Delete Category</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_panda_info('test')">Test Store Access</b-button>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="store.sSource=='pandaapi'">
            <b-col>
              <b-button variant="primary" @click="get_panda_info('getModifier')">Get Modifier</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_panda_info('uploadModifier')">Update Modifier</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_input_id('deleteModifier')">Delete Modifier</b-button>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="store.sSource=='pandaapi'">
            <b-col>
              <b-button variant="primary" @click="get_input_id('getProduct')">Get Product</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_input_id('uploadProduct')">Update Product</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_input_id('deleteProduct')">Delete Product</b-button>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="store.sSource=='pandaapi'">
            <b-col>
              <b-button variant="primary" @click="get_input_id('acceptOrder')">Accept Order</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_input_id('declineOrder')">Decline Order</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_panda_info('orders')">Get Order List</b-button>
            </b-col>
            <b-col>
              <b-button variant="primary" @click="get_input_id('getOrder')">Get Order Detail</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-modal v-model="show_input" title="Enter Id" @ok="handleOk" @hidden="resetInput">
          <div>
            <b-form-input
              v-model="opt_id"
              placeholder="Id number"
              autofocus
            ></b-form-input>
          </div>
        </b-modal>
      </b-container>
    </div>
    <div v-else>
      <b-container class="fluit" >
        <table class="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th>Store Type</th>
              <th>Store ID</th>
              <th>Store Name</th>
              <th>apiKey</th>
              <th class="text-center"><b-icon icon="plus-square" variant="success" font-scale="2" @click="add()"></b-icon></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in storeList" :key="o.id" @click="edit(o)">
              <td>{{ o.sSource }}</td>
              <td>{{ o.sourceStoreId }}</td>
              <td>{{ o.sourceName }}</td>
              <td>{{ o.apiKey }}</td>
              <td><b-icon icon="pencil-square" variant="primary" font-scale="2"></b-icon></td>
            </tr>
          </tbody>
        </table>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      token: '',
      user: null,
      storeList: [],
      store: null,
      newStore: {
        sSource: 'panda',
        sourceStoreId: '',
        sourceName: '',
        sourceKey: '',
        sourceKey2: '',
        enterpriseUrl: '',
        storeId: '',
        apiKey: '',
      },
      storetest: false,
      test_timer: false,
      test_message: '',

      show_input: false,
      doing_opt: '',
      opt_id: '',

      error: '',
      isEdit: false,
      pageleave: false,
    };
  },
  mounted() {
    const mytoken = window.localStorage.getItem('token');
    if ((mytoken == undefined) || !mytoken) {
      this.$router.push("/login");
    }
    this.token = mytoken;
    this.user = JSON.parse(window.localStorage.getItem('user'));
    if (this.user.apitype != 'admin') {
      alert("You don't have permission to do this function");
      this.$router.push("/login");
    }
    console.log("Store.vue mounted initData"); //XXXXXXXXXXXXXXXXXXXXX
    this.initData();
  },
  methods: {
    handleOk() {
      this.show_input = false;
      this.get_panda_info(this.doing_opt);
    },
    resetInput() {
      this.opt_id = '';
      this.doing_opt = '';
      this.show_input = false;
    },
    get_input_id(doing) {
      this.opt_id = '';
      this.doing_opt = doing;
      this.show_input = true;
    },
    clear_message() {
      if (this.test_timer) {
        clearTimeout(this.test_timer);
      }
      this.test_message = "";
    },
    show_message(msg, mseconds=5000) {
      this.clear_message();
      this.test_message = msg;
      this.test_timer = setTimeout(() => { this.test_message = ''; this.test_timer = flase; }, mseconds);
    },
    get_panda_info(info='test') {
      if (this.store.sSource != "pandaapi") {
        alert("Store isn't Panda Api");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      if (this.store.storeId) {
        bodyFormData.append("storeId", this.store.storeId);
      } else {
        alert("Please input Enterprise Store ID");
        return;
      }
      if (this.store.sourceStoreId) {
        bodyFormData.append("sourceStoreId", this.store.sourceStoreId);
      } else {
        alert("Please input Store ID");
        return;
      }
      if (this.store.sourceKey) {
        bodyFormData.append("sourceKey", this.store.sourceKey);
      } else {
        alert("Please input Store Access Key");
        return;
      }
      if (this.store.sourceKey2) {
        bodyFormData.append("sourceKey2", this.store.sourceKey2);
      } else {
        alert("Please input Store Security Key");
        return;
      }
      if (this.opt_id) {
        bodyFormData.append("opt_id", this.opt_id);
      }
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/panda/"+info,
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              that.show_message(rt.data.test_message, 100000);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    test_uber_store() {
      if (this.store.sSource != "ubereat") {
        alert("Store isn't Uber Eat");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      if (this.store.storeId) {
        bodyFormData.append("storeId", this.store.storeId);
      } else {
        alert("Please input Enterprise Store ID");
        return;
      }
      if (this.store.sourceStoreId) {
        bodyFormData.append("sourceStoreId", this.store.sourceStoreId);
      } else {
        alert("Please input Store ID");
        return;
      }
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/testUberStore",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              that.show_message(rt.data.test_message, 10000);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    upload_store_menu() {
      if (this.store.sSource != "ubereat") {
        alert("Store isn't Uber Eat");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      if (this.store.sourceStoreId) {
        bodyFormData.append("sourceStoreId", this.store.sourceStoreId);
      } else {
        alert("Please input Store ID");
        return;
      }
      if (this.store.apiKey) {
        bodyFormData.append("apiKey", this.store.apiKey);
      } else {
        alert("Please input Enterprise apiKey");
        return;
      }
      if (this.store.storeId) {
        bodyFormData.append("storeId", this.store.storeId);
      } else {
        alert("Please input Enterprise Store ID");
        return;
      }
      if (this.store.enterpriseUrl) {
        bodyFormData.append("enterpriseUrl", this.store.enterpriseUrl);
      } else {
        alert("Please input Enterprise Full Url");
        return;
      }
      if (this.store.sourceName) {
        bodyFormData.append("sourceName", this.store.sourceName);
      } else {
        alert("Please input Store Name");
        return;
      }
      if (this.store.sSource) {
        bodyFormData.append("sSource", this.store.sSource);
      } else {
        alert("Please Select Source");
        return;
      }

      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/uploadStoreMenus",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              that.show_message(rt.data.test_message, 10000);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    get_uber_info() {
      if (this.store.sSource != "ubereat") {
        alert("Store isn't Uber Eat");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      if (this.store.sourceStoreId) {
        bodyFormData.append("sourceStoreId", this.store.sourceStoreId);
      } else {
        alert("Please input Store ID");
        return;
      }

      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/getUberInfo",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              that.show_message(rt.data.test_message, 10000);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    changed_store_type() {
      this.clear_message();
      if (((this.store.sSource == "ubereat") || (this.store.sSource == "pandaapi")) && this.store.sourceStoreId) {
        this.storetest = true;
      } else {
        this.storetest = false;
      }
    },
    changed_store_id() {
      this.clear_message();
      if (((this.store.sSource == "ubereat") || (this.store.sSource == "pandaapi")) && this.store.sourceStoreId) {
        this.storetest = true;
      } else {
        this.storetest = false;
      }
    },
    cancel() {
      this.isEdit = false;
      this.clear_message();
    },
    add() {
      this.isEdit = true;
      this.storetest = false;
      this.store = JSON.parse(JSON.stringify(this.newStore));
      this.clear_message();
    },
    edit(o) {
      this.isEdit = true;
      this.storetest = false;
      this.clear_message();
      this.store = JSON.parse(JSON.stringify(o));
      if (((this.store.sSource == "ubereat") || (this.store.sSource == "pandaapi")) && this.store.sourceStoreId) {
        this.storetest = true;
      }
    },
    save() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      if ((this.store.id != undefined) && this.store.id) {
        bodyFormData.append("id", this.store.id);
      }
      if (this.store.sourceStoreId) {
        bodyFormData.append("sourceStoreId", this.store.sourceStoreId);
      } else {
        alert("Please input Store ID");
        return;
      }
      if (this.store.enterpriseUrl) {
        bodyFormData.append("enterpriseUrl", this.store.enterpriseUrl);
      } else {
        alert("Please input Enterprise Full Url");
        return;
      }
      if (this.store.storeId) {
        bodyFormData.append("storeId", this.store.storeId);
      } else {
        alert("Please input Enterprise Store ID");
        return;
      }
      if (this.store.apiKey) {
        bodyFormData.append("apiKey", this.store.apiKey);
      } else {
        alert("Please input apiKey");
        return;
      }
      if (this.store.sourceName) {
        bodyFormData.append("sourceName", this.store.sourceName);
      } else {
        alert("Please input Store Name");
        return;
      }
      if (this.store.sSource) {
        bodyFormData.append("sSource", this.store.sSource);
      } else {
        alert("Please Select Source");
        return;
      }
      if (this.store.sSource == 'pandaapi') {
        if (this.store.sourceKey) {
          bodyFormData.append("sourceKey", this.store.sourceKey);
        } else {
          alert("Please Input Store Access Key");
          return;
        }
        if (this.store.sourceKey2) {
          bodyFormData.append("sourceKey2", this.store.sourceKey2);
        } else {
          alert("Please Input Store Security Key");
          return;
        }
      }
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/storeUpdate",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              that.isEdit = false;
              if (rt.data.store) {
                that.store = rt.data.store;
                that.getStoreList();
              } else {
                alert("Not data");
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    initData() {
      this.getStoreList();
    },
    getStoreList() {
      this.clear_message();
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/storeList",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              if (rt.data.storeList) {
                that.storeList = rt.data.storeList;
              } else {
                that.storeList = [];
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
              that.$router.push("/login");
            }
          }
        })
        .catch(function (error) {
          console.log("Store.vue getStoreList error", response); //XXXXXXXXXXXXXXXXXXXXX
          that.error = error;
          that.$router.push("/login");
        });
    },
  }
}
</script>
<style scoped>
.toleft {
  text-align: left;
}
</style>